<template>
    <div class="main-content" style="
      padding-bottom: 10vh;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 100%;
        background-position: center !important;
      ">
        <div class="dashboard-banner position-relative">
        </div>
        <div class="m-3 mx-3 mt-4">
            <div class="header">
                <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack">
                <h1 class="main_text text text_main_title">{{ $t("convert_etnex") }}</h1>
                <div></div>
                <!-- <i class="fa fa-history info-icon" aria-hidden="true" @click="goHistory"></i> -->
                <!-- <img src="../../../assets/images/etnex_buy/question.png" alt="Info" class="info-icon"> -->
            </div>
            <div class="sell-etnex-container black-dark-blue-card mb-3">
              <div class="sell-card">
                <div class="card-header">
                  <img src="../../../assets/images/etnex_sell/icon.png" alt="Logo" class="logo" width="35px"/>
                  <span class="text weight_600 text_16 text-light-green">{{ $t("convert_etnex") }}</span>
                </div>
                <div class="card-body">
                  <div class="available text_m_a">
                    {{ $t("etnex_freeze2") }} {{ this.etnex }} ETNEX
                  </div>
                  <div class="available text_m_a">
                    {{ $t("available_etnex_convert") }} {{parseFloat(this.limit).toFixed(2) }} ETNEX
                  </div>
                  <!-- <div class="mb-3">
                    <input type="text" class="form-control" placeholder="Please enter quantity">
                  </div> -->
                  <div class="custom-input-group black-dark-blue-input mb-3">
                    <input type="number" class="custom-input form-control" :placeholder="$t('please_enter_quantity')" id="amount"/>
                    <div class="currency-label text weight_600">ETNEX</div>
                  </div>
                  <!-- <div class="text_m_a">
                    ~Min. 5 ETNEX
                  </div> -->
                </div>
              </div>
            </div>
            <button class="sell-button" @click="open">
                {{ $t("convert") }}
            </button>
            <!-- <div class="info-box black-dark-blue-card mt-2">
              <div class="info-line">
                <span class="info-title">{{ $t("market_price") }}</span>
                <span class="info-content">1 ETNEX = {{ this.market_price }} USDT</span>
              </div>
              <div class="info-line">
                <span class="info-title">{{ $t("estimated_charges") }}</span>
                <span class="info-price">$1.00 {{$t("1_minute")}}</span>
              </div>
            </div> -->

            <h2 class="text_h2_etnex text text-light-green mt-4 pt-10">{{ $t("recent_transactions") }}</h2>
            <div class="scrollable-container">
              <div v-if="transferRecord.length > 0">
                <div class="notification-card" v-for="record in transferRecord" :key="record.id">
                    <div class="icon">
                        <img src="../../../assets/images/etnex_wallet/leftright.png" alt="icon" class="icon" width="50px">
                    </div>
                    <div class="content">
                    <div class="upper-row">
                        <span class="title text">{{ $i18n.locale === 'en' ? record.detailen : record.detail }}</span>
                        <span class="date text">{{ formatDate(record.created_at) }}</span>
                    </div>
                    <div class="lower-row">
                        <span class="con_address text"></span>
                        <span class="change" :style="{ color: record.action === '-' ? 'red' : '#A7FDED' }">{{ record.action }}${{ record.found }}</span>
                        
                    </div>
                  </div>
                </div>
              </div>
              <h3 v-else class="no-data-text text">
                {{ $t("no_data") }}
              </h3>
              <div class="purchase-statement-container" style="text-align: center; padding-top: 10px;" v-if="moreRecords">
                    <button class="make-purchase-button" style="width: 40% !important;" @click="loadMore">
                        {{ $t("load_more") }}
                    </button>
                </div>
            </div>
            
        </div>
        <enter-pin ref="enterPin" @submit-pin="submitForm"></enter-pin>
        <Dialog ref="msg"></Dialog>
        <Success v-if="SuccessModal" @closePopup="SuccessModal = false" :message="successMessage"></Success>
    </div>
  </template>
  <script>
  import EnterPin from '../pin/enter_pin.vue';
  import Dialog from "../../../components/dialog.vue";
  import Success from '../popup/profilesuccess.vue';
  import { getMemberInfo, getDownloadLink, getUserWalletRecord, userChangeWallet } from "../../../system/api/api";
  import { handleError } from "../../../system/handleRes";
  export default {
    components: {
      EnterPin,
      Dialog,
      Success,
    },
    data() {
      return {
        packagetype: [],
        transfer_type: 'point2',
        SuccessModal: false,
        isLoading: false,
        etnex: 0,
        limit: 0,
        market_price: 0,
        currentPage: 1,
        transferRecord: [],
        moreRecords: false,
      };
    },
    mounted() {
      this.loadItems();
      this.loadItems2();
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      goHistory() {
        this.$router.push("/web/sell/sellRecord");
      },
      open() {
        if (document.getElementById("amount").value == 0 || document.getElementById("amount").value == "") {
          this.$refs.msg.makeToast("danger", this.$t("amount_null"));
        } else {
          this.$refs.enterPin.open();
        }
        
      },

      submitForm(pin) {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("amount", document.getElementById("amount").value);
        formData.append("transfer_type", this.transfer_type);
        formData.append("sec_password", pin);

        var result = userChangeWallet(formData);
        var self = this;
        result
          .then(function (value) {
            self.isLoading = false;
            if (value.data.code == 0) {
                self.successMessage = "success";
                self.SuccessModal = true;
                setTimeout(() => {
                    self.$router.push("/web/mywallet");
                }, 2000);
            } else {
              self.$refs.msg.makeToast("danger", self.$t(value.data.message));
            }
          })
          .catch(function (error) {
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });
      },

      loadItems(){
        var result = getMemberInfo();
        var self = this;
        this.isLoading = true;
        result
          .then(function (value) {
            self.isLoading = false;
            self.etnex = value.data.data.point2;
            self.limit = value.data.data.etnex_limit;
            
          })
          .catch(function (error) {
            self.isLoading = false;
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });

        var result_lookup = getDownloadLink();
        result_lookup
          .then(function (value) {
            self.market_price = value.data.data.system.etnex_price;
          })
          .catch(function (error) {
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });
      },

      loadMore() {
        this.currentPage++;
        this.loadItems2();
      },
      formatDate(datetime) {
        return datetime.split(' ')[0];
      },

      loadItems2(){
        var searchParam = "&found_type=7";
        var result = getUserWalletRecord(
            this.transfer_type,
          this.currentPage,
          searchParam, );
        var self = this;
        result
          .then(function (value) {
            self.transferRecord = value.data.data.record.data;
            if (!value.data.data.next_page_url) {
              self.moreRecords = false;
            }else{
              self.moreRecords = true;
            }
            // console.log(self.walletRecord);
  
          })
          .catch(function (error) {
            self.isLoading = false;
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });
      }
    },
  };
  </script>
  
<style scoped>
  /* text css */
  .text{
    color: var(--Main, #FFF);
    font-family: Poppins;
    font-style: normal;
  }
  

  .main_text{
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin-top: 7px;
  }

  .text_16{
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }

  .weight_600{
    font-weight: 600;
  }
  .text_h2_etnex{
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
  
  .text_main_title{
    flex-grow: 1;
    text-align: center;
  }
  /* text css end */
  
  .sell-etnex-container {
    background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
    max-width: 100%;
    margin: auto;
    color: white;
    padding: 12px;
    font-family: 'Poppins', sans-serif;
    border-radius: 8px;
  }
  
  .header {
    display: flex;
    /* padding: 0px 16px; */
    margin-top: 4rem !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  .info-icon{
    cursor: pointer;
    color: #fff;
    font-size: 18px;
  }


  .sell-card {
  border-radius: 12px;
  color: #fff;
  padding: 8px;
  font-family: 'Poppins', sans-serif;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0px !important;
}

.logo {
  max-width: 25%;
  margin-right: 0.5rem;
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: 0px !important;
}

.available {
  margin-bottom: 1rem;
}

.form-control {
  border: 2px solid #0BDDCC;
  background-color: transparent;
  color: #fff;
}

.text_m_a{
  color: var(--Secondary, #7E8088);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
 

.custom-input-group {
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden; 
  background-color: transparent;
  color: #fff;
}

.custom-input {
  flex-grow: 1;
  border: none;
  padding: 10px;
  color: white;
  background: transparent;
}

.currency-label {
  padding: 10px;
  color: white;
  background: transparent;
}

.custom-input:focus {
  outline: none;
}
.sell-button {
    width: 100%;
    border-radius: 8px;
    background-image: url('../../../assets/images/etnex_home/colourful_bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    color: rgb(0, 0, 0);
    padding: 12px;
    border: none;
    color: var(--Dark-Purple, #251740);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }

  .info-box {
    background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
    border-radius: 8px;
    color: white;
    border-radius: 8px;
    padding: 16px 20px;
    display: flex;
    font-family: Poppins;
    flex-direction: column;
    justify-content: space-between;
  }

  .info-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0; 
  }

  .info-title{
    color: var(--Secondary, #7E8088);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .scrollable-container {
    max-height: 240px; 
    overflow-y: auto; 
  }
  .notification-card {
      display: flex;
      align-items: center;
      background: linear-gradient(90deg, #152331 0%, #000000 100%);
      color: #fff;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 7px;
  }

  .icon {
      margin-right: 5px;
      flex-shrink: 0;
  }

  .icon img {
      background-color: transparent;
      border-radius: 5px;
      padding: 8px;
  }

  .content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
  }

  .upper-row, .lower-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
  }

  .title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
  }

  .date {
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 1px;
  }

  .stat-circle-container {
      flex: 0 0 auto;
  }

  circle.bg
  {
      fill: #C20651;
      stroke: #251740;
      stroke-width: 3;
  }

  text
  {
      font-size: 4px;
      text-anchor: middle;
      fill: #fff;
  }
  .text_main_title{
    flex-grow: 1;
    text-align: center;
  }


  .no-data-text{
    font-weight: 400;
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
    margin-top: 40px;
    color: var(--Secondary, #7E8088) !important;
  }
 </style>
  